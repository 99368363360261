import React, { useState, useEffect } from "react";
import { HashRouter } from "react-router-dom";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import { onError } from "./libs/errorLib";

import "./scss/style.scss";
import NRoutes from "./NRoutes";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const session = await Auth.currentSession();
      setIsAuthenticated(true);
      setUser({
        user: session.idToken.payload.sub,
        email: session.idToken.payload.email,
      });
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <AppContext.Provider value={{ isAuthenticated, setIsAuthenticated, user, setUser }}>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <NRoutes />
          </React.Suspense>
        </HashRouter>
      </AppContext.Provider>
    )
  );
}

export default App;
