export function onError(error) {
    let message = error.toString();
  
    // Auth errors
    if (!(error instanceof Error) && error.message) {
      console.error(error);
      message = error.message;
    }
  
    alert(message);
  }