import React from "react";
import { Route, Switch } from "react-router-dom";
import UnauthenticatedRoute from "./reusable/UnauthenticatedRoute";
// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Land = React.lazy(() => import("./views/pages/land/Land"));
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));

export default function NRoutes() {
  return (
    <Switch>
      <UnauthenticatedRoute exact path="/login" name="Login Page">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/xregister" name="Register Page">
        <Register />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/" name="Land Page">
        <Land />
      </UnauthenticatedRoute>
      <Route path="/home" name="Home" render={(props) => <TheLayout {...props} />} />
    </Switch>
  );
}
