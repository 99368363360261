const config = {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
      REGION: "us-east-1",
      BUCKET: "coltabaco-app",
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://hubp24z6t9.execute-api.us-east-1.amazonaws.com/prod",
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_jEJtJz7V0",
      APP_CLIENT_ID: "7oopdsel1lp71h218hbh6eg14s",
      IDENTITY_POOL_ID: "us-east-1:721d7b52-da3c-4d93-9adb-9ac50fec2bc3",
    },
  };
  
  export default config;